<template>
  <div
    id="ComponentePessoaForm"
    class="p-2 card"
  >
    <h1>{{ this.formValues.nome }}</h1>

    <b-overlay
      :show="loadingShow"
      rounded="lg"
      opacity="1"
      class="pt-2"
    >
      <b-row class="mb-1">
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="CPF *"
            label-for="Pessoa-CPF"
          >
            <Cleave
              id="Pessoa-CPF"
              class="form-control"
              :options="mask.cpf"
              v-model="formValues.cpf"
              :disabled="formDisabled.cpf"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Nome Completo *"
            label-for="Pessoa-Nome"
          >
            <b-form-input
              v-model="formValues.nome"
              id="nome"
              name="Pessoa-Nome"
              :disabled="formDisabled.nome"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <empresaSelect
            :values="formValues.empresa"
            :idEmpresa="idEmpresa"
            :required="false"
            :disabledProp="formDisabled.empresa"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <liderSelect
            :values="formValues.lider"
            :idLider="idLider"
            :idEmpresa="idEmpresa"
            :required="false"
            :disabledProp="formDisabled.lider"
            :modoVisualizacao="true"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Perfil *"
            label-for="Perfil"
          >
            <div
              class="form-control itens"
              :style="'background-color: #efefef'"
            >
              <span
                v-for="perfil in formValues.perfil"
                :key="perfil.id_perfil"
                v-text="perfil.descricao"
              ></span>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Email *"
            label-for="Pessoa-email"
          >
            <b-form-input
              v-model="formValues.email"
              id="email"
              name="Pessoa-email"
              :disabled="formDisabled.email"
              Type="email"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Telefone"
            label-for="Pessoa-telefone"
          >
            <Cleave
              v-model="formValues.telefone"
              id="telefone"
              class="form-control"
              :options="mask.fone"
              name="Pessoa-telefone"
              :disabled="formDisabled.telefone"
              placeholder="(00) 0 0000 0000"
            />
          </b-form-group>
      </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Data de nascimento *"
            label-for="Pessoa-nascimento"
          >
            <b-form-input
              v-model="formValues.dtNascimento"
              id="dtNascimento"
              name="Pessoa-nascimento"
              :disabled="formDisabled.dtNascimento"
              placeholder="01/01/2000"
              type="date"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" xl="3" class="mb-1">
          <generoSelect
            :values="formValues.genero"
            :idGenero="idGenero"
            :required="false"
            :disabledProp="formDisabled.genero"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <estadoCivilSelect
            :values="formValues.estadoCivil"
            :idEstadoCivil="idEstadoCivil"
            :required="false"
            :disabledProp="formDisabled.estadoCivil"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Número de filhos"
            label-for="Pessoa-filhos"
          >
            <b-form-input
              type="text"
              v-model="formValues.filhos"
              id="filhos"
              name="Pessoa-filhos"
              :disabled="formDisabled.filhos"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <escolaridadeSelect
            :values="formValues.escolaridade"
            :idEscolaridade="idEscolaridade"
            :required="false"
            :disabledProp="formDisabled.escolaridade"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Data de Admissão"
            label-for="Pessoa-admissao"
          >
            <b-form-input
              v-model="formValues.dtAdmissao"
              id="admissao"
              name="Pessoa-admissao"
              :disabled="formDisabled.dtAdmissao"
              placeholder="01/01/2000"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <regimeSelect
            :values="formValues.regime"
            :idRegime="idRegime"
            :required="false"
            :disabledProp="formDisabled.regime"
            :modoVisualizacao="true"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Renda Bruta"
            label-for="Pessoa-renda"
          >
            <b-input-group>
              <b-input-group-prepend is-text>R$</b-input-group-prepend>
              <cleave
                id="renda"
                v-model="formValues.renda"
                class="form-control"
                :options="mask.renda"
                name="Renda"
                placeholder="0,00"
                :disabled="formDisabled.renda"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" xl="3" class="mb-1">
          <turnoSelect
            :values="formValues.turno"
            :idTurno="idTurno"
            :required="false"
            :disabledProp="formDisabled.turno"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <funcaoSelect
            :values="formValues.funcao"
            :idFuncao="idFuncao"
            :required="false"
            :disabledProp="formDisabled.funcao"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <setorSelect
            :values="formValues.setor"
            :idSetor="idSetor"
            :required="false"
            :disabledProp="formDisabled.setor"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <gerenciaSelect
            :values="formValues.gerencia"
            :idGerencia="idGerencia"
            :required="false"
            :disabledProp="formDisabled.gerencia"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" xl="3" class="mb-1">
          <diretoriaSelect
            :values="formValues.diretoria"
            :idDiretoria="idDiretoria"
            :required="false"
            :disabledProp="formDisabled.diretoria"
          />
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <unidadeSelect
            :values="formValues.unidade"
            :idUnidade="idUnidade"
            :required="false"
            :disabledProp="formDisabled.unidade"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>

import GeneroSelect from "@/views/components/custom/pessoas/GeneroSelect.vue";
import estadoCivilSelect from "@/views/components/custom/pessoas/EstadoCivilSelect.vue";
import regimeSelect from "@/views/components/custom/pessoas/RegimeSelect.vue";
import escolaridadeSelect from "@/views/components/custom/pessoas/EscolaridadeSelect.vue";
import turnoSelect from "@/views/components/custom/pessoas/TurnoSelect.vue";
import funcaoSelect from "@/views/components/custom/pessoas/FuncaoSelect.vue";
import gerenciaSelect from "@/views/components/custom/pessoas/GerenciaSelect.vue";
import diretoriaSelect from "@/views/components/custom/pessoas/DiretoriaSelect.vue";
import setorSelect from "@/views/components/custom/pessoas/SetorSelect.vue";
import unidadeSelect from "@/views/components/custom/pessoas/UnidadeSelect.vue";
import empresaSelect from "@/views/components/custom/pessoas/EmpresaGrupoSelect.vue";
import liderSelect from "@/views/components/custom/pessoas/LiderSelect.vue";
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import { modalGenericModel } from '@/libs/sweetalerts'

import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCardText,
  BAlert,
  BOverlay,
  BModal,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BCardText,
    BAlert,
    BOverlay,
    BModal,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    Cleave,
    estadoCivilSelect,
    escolaridadeSelect,
    regimeSelect,
    turnoSelect,
    funcaoSelect,
    gerenciaSelect,
    diretoriaSelect,
    setorSelect,
    unidadeSelect,
    liderSelect,
    empresaSelect,
    GeneroSelect
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      userData: getUserData(),
      formValues: {
        cpf: "",
        nome: "",
        perfil: [],
        email: "",
        telefone: "",
        dtNascimento: "",
        genero: {},
        estadoCivil: {},
        filhos: 0,
        escolaridade: {},
        dtAdmissao: "",
        regime: {},
        renda: "",
        turno: {},
        funcao: {},
        setor: {},
        gerencia: {},
        diretoria: {},
        lider: {},
        empresa: {},
        unidade: {},
      },
      formDisabled: {
        cpf: false,
        renda: false,
        dtAdmissao: false,
        filhos: false,
        genero: false,
        dtNascimento: false,
        telefone: false,
        email: true,
        nome: false,
        gerencia: false,
        diretoria: false,
        lider: false,
        empresa: false,
        funcao: false,
        setor: false,
        turno: false,
        regime: false,
        escolaridade: false,
        estadoCivil: false,
        unidade: false,
      },

      idEstadoCivil: 0,
      idEscolaridade: 0,
      idRegime: 0,
      idTurno: 0,
      idFuncao: 0,
      idSetor: 0,
      idGerencia: 0,
      idDiretoria: 0,
      idUnidade: 0,
      idEmpresa: null,
      idLider: 0,
      idGenero: 0,

      loadingShow: true,

      mask: {
        cpf: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        fone: {
          delimiters: ["(", ")", " ", " ", " "],
          blocks: [0, 2, 1, 4, 4],
          numericOnly: true,
        },
        dtNascimento: {
          delimiters: ["/", "/"],
          blocks: [2, 2, 4],
          numericOnly: true,
        },
        renda: {
          numeral: true,
          numeralDecimalScale: 2,
          signBeforePrefix: true,
          numeralDecimalMark: ",",
          delimiter: "",
        },
      },
    };
  },

  props: {
    propEditPessoa: {
      type: Object,
      required: true
    }
  },
  async mounted() {
    this.desabilitarTodosCampos();
    if (this.$props.propEditPessoa?.id_colaborador) {
      await this.buscaDadosColaboradorPessoa()
    } else {
      modalGenericModel(
        'Resultados não encontrados',
        'A empresa buscada não possui resultados para este módulo',
        require('@/assets/custom-icons/exclamationMark.png'),
        'Entendi'
      ).then(() => {
        this.$router.push(
          {
            name: 'bem-vindo'
          }
        );
      });
    }
  },

  methods: {
    PessoaVisualizada(id_pessoa_visualizada) {
      let form = {'id_pessoa' : this.userData.pessoa.id_pessoa,'id_pessoa_visualizada' : id_pessoa_visualizada}
        this.$http
          .post(
            this.$api.PessoaVisualizada(),
            form
          );
    },

    setaDadosFormulario(dados) {
      this.idEmpresa = dados.id_empresa;
      this.formValues.nome = dados.nome;
      this.formValues.cpf = dados.cpf;
      this.formValues.telefone = dados.telefone;
      this.formValues.dtNascimento = dados.nascimento;
      this.formValues.email = dados.email;
      this.formValues.filhos = dados.numero_filhos;
      this.formValues.renda = dados.renda_bruta;
      this.formValues.dtAdmissao = dados.admissao;
      this.idGenero = dados.id_genero;
      this.idEstadoCivil = dados.id_estado_civil;
      this.idEscolaridade = dados.id_escolaridade;
      this.idRegime = dados.id_regime;
      this.idTurno = dados.id_empresa_turno;
      this.idFuncao = dados.id_empresa_funcao;
      this.idSetor = dados.id_empresa_setor;
      this.idGerencia = dados.id_empresa_gerencia;
      this.idDiretoria = dados.id_empresa_diretoria;
      this.idUnidade = dados.id_empresa_unidade;
      this.idLider = dados.id_lider;
    },

    async buscaDadosColaboradorPessoa() {
      let rotacolab = this.$helpers.preparaRotaComParametros(
        this.$api.BuscaDadosPessoa,
        "{colaborador}",
        this.propEditPessoa.id_colaborador
      );

      await this.$http.get(rotacolab)
      .then(async (res) => {
        if (res.status == 200) {
          this.setaDadosFormulario(res.data);
          await this.buscaPerfisColab(this.propEditPessoa.id_colaborador);
          this.PessoaVisualizada(res.data.id_pessoa);
          this.loadingShow = false;
        }
      }).catch(() => {
        modalGenericModel(
          'Resultados não encontrados',
          'A empresa buscada não possui resultados para este módulo',
          require('@/assets/custom-icons/exclamationMark.png'),
          'Entendi'
        ).then(() => {
          this.$router.push(
            {
              name: 'bem-vindo'
            }
          );
        });
      })
    },

    async buscaPerfisColab(id_colab) {
      let rotaPerfis = this.$helpers.preparaRotaComParametros(
        this.$api.BuscaPerfisColab,
        "{colaborador}",
        id_colab
      );

      await this.$http.get(rotaPerfis)
      .then((res) => {
        if (res.status == 200) {
          this.formValues.perfil = res.data;
        }
      })
    },

    desabilitarTodosCampos() {
      const props = Object.getOwnPropertyNames(this.formDisabled);
      props.forEach((chave) => {
        if (chave != "__ob__") {
          this.formDisabled[chave] = true;
        }
      });
    }
  }
};
</script>

<style>
#ComponentePessoaForm .itens > span {
  display: inline-block;
  background: #7030A0;
  margin: 0 13px 0 0;
  padding: 3px 7px;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  border-radius: 5px;
}
#ComponentePessoaForm .itens > span:last-child {
  margin-right: 0;
}
</style>
